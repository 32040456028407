import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/database";
import "firebase/auth";
//import "firebase/firestore";


var firebaseConfig = {
    apiKey: "AIzaSyC-0U5D6wuXr9NM3rsqsOCagRbyoht9-s0",
    authDomain: "expo-gtp.firebaseapp.com",
    databaseURL: "https://expo-gtp-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "expo-gtp",
    storageBucket: "expo-gtp.appspot.com",
    messagingSenderId: "172429082641",
    appId: "1:172429082641:web:5f8d063fa5d9899ccb71b0",
    measurementId: "G-CKMCYZ4HZ1"
  };
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

import MicroModal from 'micromodal';
import Panzoom from '@panzoom/panzoom';


var urlvarid = getQueryVariable('id');

if(!urlvarid){urlvarid=1}
window.id = urlvarid;

console.log('urlvarid',urlvarid);

$(function() {

    MicroModal.init( {
        
        
            onShow: function() {$('body').addClass('modal-active')},
            onClose: modal => $('body').removeClass('modal-active')
        }
    );



    // Handler for .ready() called.


    // This example also showcases the canvas option
    // Pointer events anywhere in the parent move
    // the target.
    const elem = document.querySelector('.panzoom'); 
    const panzoom = Panzoom(elem, { canvas: false,duration:0,animate:true,maxScale:6,easing:"cubic-bezier(.42,0,.23,.99)", contain: 'outside',startScale:1,origin:"50% 50%",step:.3 })
    const parent = elem.parentElement
    // No function bind needed
   // parent.addEventListener('wheel', panzoom.zoomWithWheel)
   var supportsPassive = false;
   try {
     addEventListener("test", null, { get passive() { supportsPassive = true; } });
   } catch(e) {}
   $('passive').disabled = !supportsPassive;
    // This demo binds to shift + wheel
    parent.addEventListener('wheel', function(event) {
        //if (!event.shiftKey) return
        console.log(event);
        panzoom.zoomWithWheel(event, {duration:400,animate:true,contain: 'outside'});
    
    //    var s = panzoom.getScale();
       
    //    if(event.deltaY>0) {
    //        s=s-0.3; 
    //        var data = { clientX: $('.live').width()/2 , clientY: $('.live').height()/2 }
    //         panzoom.zoomToPoint(s,data, {duration:800,animate:true,contain: 'outside'});
    //     }
    //    else{
    //        panzoom.zoomWithWheel(event, {duration:400,animate:true,contain: 'outside'}); }
    //    if(s<=1){s=1;}
       
        setactiveslide(false);
    },{passive:false});

    parent.addEventListener('dblclick', function(event) {
        var s = panzoom.getScale();
        panzoom.zoomToPoint(s*2, event,{duration:800,animate:true,contain: 'outside'});
    })
    elem.addEventListener('panzoomstart', function(event) {
        console.log('move');
        setactiveslide(false);
    });

   // console.log('tst',parent.getContext("2d")) ;

    document.onkeydown = checkKey;

    function checkKey(e) {

        e = e || window.event;

        if (e.keyCode == '38') {
            // up arrow
        }
        else if (e.keyCode == '40') {
            // down arrow
            savebuttons(id);
        }
        else if (e.keyCode == '37') {
            if(!$('body').hasClass('modal-active')) {
                nextslide(false);
            }
        // left arrow
        }
        else if (e.keyCode == '39') {
            if(!$('body').hasClass('modal-active')) {
                nextslide();
            }
        // right arrow
        }

    }

    var basenumber = 4;
    var panbase = {x:0,y:0};
    var space = {width:6240 ,height:4160}
    //var space = {width:1920*2 ,height:1080*2}
   // var space = {width:1920 ,height:1080}

   $('.layer').css('display','none');

   if(urlvarid==0){
    var space = {width:1920*2 ,height:1080*2 }
    //$('#layer0').attr('src','img/vis_strat_bambuu_20200203_06012020_compressed_halfsize-72dpi-8bit.webp');
   
    $('#layer0').css('display','block');
    $('.buttons').css('display','none')
    $('.navigationbuttons').css('display','none')
    $('.optionbuttons.top').css('display','none')
    

 }

   if(urlvarid==1){
       var space = {width:11811/2 ,height:8350/2 }
       //$('#layer0').attr('src','img/vis_strat_bambuu_20200203_06012020_compressed_halfsize-72dpi-8bit.webp');
      
       $('#layer1').css('display','block');

       

    }
  //  var space = {width:19499/4 ,height:11170/4 }
  if(urlvarid==2){
    var space = {width:3841 ,height:2159 }
    
    $('#layer2').css('display','block');
  }

  if(urlvarid=='3N2PKWY5Z1'){
    var space = {width:1335.1 ,height:702.7 }
     
    $('#layer3').css('background','white').css('display','block');
  }

  if(urlvarid=='4'){
    var space = {width:3134.22 ,height:1763 }
     
    $('#layer4').css('background','white').css('display','block');
  }

    var positionscale;

    var padding = 400; //default, will be overwritten by calculation

    //var padding = $(window).width() *.3;

    var aspectratio = space.width / space.height;

    $('.layer').css('width',space.width+'px');

        $('.layers').css('width',space.width+'px');
        $('.layers').css('height',space.height+'px');

    function adjustscale(){
        console.log('adjustscale');
        padding = parseInt($('.expo').css('padding-left')) + parseInt($('.expo').css('padding-right'));

        aspectratio = space.width / space.height;
        positionscale = (1/ (space.width / basenumber / $('.live').width() )).toFixed(5);;
        if(  $('body').hasClass('fullscreenmode') ) {positionscale = (1/ (space.width / basenumber / $(window).width() )).toFixed(5);; }

        var bottomspace = 250;
      

        var winw = $(window).width();
        var winh = $('.live').height();
        var winw = $(window).width() - padding;

        var winw = $('.live').width();
        if(  $('body').hasClass('fullscreenmode') ) { bottomspace =0; padding=0; var winw = $(window).width(); }
        
        var s = winw /space.width;
        $('.layers').css('transform','scale('+s+')');



        var winw = $(window).width()-padding;
        var winh = $(window).height() -bottomspace;


        if(winw/winh <aspectratio){
            console.log('bigger than aspect');
            var s = winw /space.width;
        
            $('.panzoom').css('max-height', '100%');
            $('.panzoom').css('height', '100%');

            $('.live').css('transform','scale(1)');
        }
        else{
            console.log('smaller than aspect');
            var s = winh /winw/aspectratio/2;
            var h = 100 + (100* (   winw /winh   - aspectratio) );
            if(h<100){ h=100}
             $('.panzoom').css('height', h+'%');
            $('.panzoom').css('max-height', '500%');
            //var s = 0.9
        // $('.live').css('transform','scale('+s+')');
        }

        $('.workspace').css('height', 'calc( (100vw - '+padding+'px) *'+1/aspectratio+')');
        $('.workspace').css('max-height', 'calc( (100vh - '+bottomspace+'px)');
        
        
      //  if(s>1) s=1;

      //  $('.wswrapper').css('transform','scale('+s+')');
    //    $('.wswrapper').css('height',1920*1/aspectratio+'px');
     //   $('.live').css('height', 'calc( (100vw - '+padding+'px) *'+1/aspectratio+')');
   //     $('.live').css('max-height', 'calc( (100vw - '+padding+'px) *'+1/aspectratio+')');

     //   $('.workspace').css('width',space.width+'px');
     //   $('.workspace').css('height',space.height+'px');
        var s2 = 1920 / space.width;
      //  $('.workspace').css('transform','scale('+s2+')');


        
        var winh = $(window).height()-padding;
      
        var sh = winh / (1080*aspectratio *s);

        if(sh>1) sh=1;
    //    $('.live').css('transform','scale('+sh+')');
    
   
        // $('.layers').css('width',space.width+'px');
        // $('.layers').css('height',space.height+'px');
        // var s2 = 1920 / space.width *2;
        // $('.layers').css('transform','scale('+s2+')');        
        //var pz = panzoom.getScale();
        var pp = panzoom.getPan();
        //panzoom.zoom(4, { animate: true, duration: duration });
        setTimeout(() => panzoom.pan(panbase.x*positionscale, panbase.y*positionscale, { animate: false, duration: duration/3,contain: 'outside', }));




    }

   // $( ".buttonwrap" ).draggable({ axis: "x" });
    $( ".innerbuttons" ).sortable({ items:"> .slide", axis: "x",containment: "parent",cursorAt: { left: 40,top:100 },revert: 50, tolerance: 'pointer',    cursor: 'move'});
    $( ".innerbuttons" ).disableSelection();
    $( ".innerbuttons" ).sortable({
        start: function (event, ui) {  
          //  ui.helper.css('top', "50px"); 

            ui.helper.css('transition', "all .5s"); 
        },
        beforeStop: function (event, ui){ 
        //    ui.helper.css('margin-top',0);
            ui.helper.css('transition','');  
        },


        stop: function( event, ui ) {
            $( ".buttonwrap" ).css('top','')
            $( ".buttonwrap" ).css('left','');
            savebuttons(id);
        }
      });

    adjustscale();
    $(window).on('resize',adjustscale);
    $('body').addClass('scale-adjusted');

    elem.addEventListener('panzoomchange', (event) => {
        console.log(positionscale,event.detail) // => { x: 0, y: 0, scale: 1 }
        panbase = panzoom.getPan();
        panbase.x = (panbase.x/positionscale).toFixed(5);
        panbase.y = (panbase.y/positionscale).toFixed(5);
         if(event.detail.scale<1.02) {
             panzoom.zoom(1,{silent:true, animate: true, duration: duration/2});
             panzoom.pan(0, 0,{silent:true, animate: true, duration: duration/2})    ;   
         }

     //   if(event.detail.scale<=1) {panzoom.setOptions( {contain: 'outside'});}
     //   else {panzoom.setOptions({contain: 'outside'});}
        //else {panzoom.setOptions({contain: false});}
      })


    var duration = 1500;  

    $('#reset').on('click',function(){
        panzoom.zoom(1,{animate:true,duration:duration,silent:true,contain: false});
        setTimeout(() => panzoom.pan(0,0, {animate:true,duration:duration,contain: false }),100);
        setactiveslide(this);
    })

    $('#zoominx1').on('click',function(){
            panzoom.zoom(4, { animate: true, duration: duration,silent:true,contain: false  });
            setTimeout(() => panzoom.pan(  (432*positionscale).toFixed(5) , (118*positionscale).toFixed(5) , { animate: true, duration: duration,contain: false }),2);
            setactiveslide(this);
        })

    $('#zoominx2').on('click',function(){
      
       // var s = panzoom.getScale();
     // setTimeout(() => panzoom.zoom(s*1.001,{animate:false,duration:10,silent:false,easing:"ease-out",contain: false}),10);
     panzoom.zoom(3,{animate:true,duration:duration,silent:true,contain: false });
        setTimeout(() => panzoom.pan( (200*positionscale).toFixed(5) ,(-165*positionscale).toFixed(5), {animate:true,duration:duration,contain: false}),2);

        //setTimeout(() => panzoom.zoom(s,{animate:true,duration:0,silent:true,easing:"ease-out"}),10);
      //  setTimeout(() => panzoom.zoom(3,{animate:true,duration:duration,silent:false}),2000);

       setactiveslide(this);
    })
    
    $('#zoominx3').on('click',function(){
        panzoom.zoom(2,{animate:true,duration:duration,silent:true,contain: false});
        setTimeout(() => panzoom.pan(10*positionscale,222*positionscale, {animate:true,duration:duration,contain: false}),2);
        setactiveslide(this);
    })

    
    $('#zoominx4').on('click',function(){
        panzoom.zoom(3, { animate: true, duration: duration,silent:true,contain: false });
        setTimeout(() => panzoom.pan(-320*positionscale, -205*positionscale, { animate: true, duration: duration ,contain: false}),2);
        setactiveslide(this);
    })

    $('.buttons').on('click','.dynamicbutton',function(){
       
        var data = JSON.parse( $(this).attr('data-slide') );
        console.log(data);
        panzoom.zoom(data.zoom, { animate: true, duration: duration,silent:true,contain: false });
        setTimeout(() => panzoom.pan(data.x*positionscale, data.y*positionscale, { animate: true, duration: duration ,contain: false}),2);
        setactiveslide(this);
    })
 

   
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // User is signed in.
          $('#loginstatus').html(' ' + user.email);
          $('body').addClass('loggedin');
        } else {
          // No user is signed in.
        }
      });
   
    function login(){
        
        var email = $('#inputloginuser').val();
        var password = $('#inputloginpass').val();
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in 
          var user = userCredential.user;
          console.log('login',user);
          $('body').addClass('loggedin');
          $('#loginstatus').html(' ' + user.email);
         
          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          $('#loginstatus').html(errorMessage);
          // ..
        });        

    }

    function logout(){
        firebase.auth().signOut().then(() => {
             $('#loginstatus').html('Log out succesfull!');
             $('body').removeClass('loggedin');
             setTimeout(function(){ $('#loginstatus').html(   $('#loginstatus').attr('data-defaulttext')   );},2000);
          }).catch((error) => {
            $('#loginstatus').html(error);
            // An error happened.
          });
    }



    loadexpo(id);
    function loadexpo(id){
        console.log('loadexpo');
        var expometa = firebase.database().ref('/expos/'+id);
        expometa.once('value', (snapshot) => {
            //console.log('snap',snapshot.val());
            var expodata = snapshot.val();
            console.log(expodata.image);
            
            $('h1').html( expodata.h1 )
            $('h2').html( expodata.h2 )

            loadslides(id);

          });
    }
    

    

    function loadslides(id){
       console.log('loadslides');
        // var starCountRef = firebase.database().ref('expos/');
        // starCountRef.on('value', (snapshot) => {
        //   const data = snapshot.val();
        //   console.log('data',data);
        // });


       // var newPostKey = firebase.database().ref().child('expos').push().key;
       $('.innerbuttons').html('');

        var slides = firebase.database().ref('/expos/'+id+'/slides/');
        slides.once('value', (snapshot) => {
            //console.log('snap',snapshot.val());
            var slidesdata = snapshot.val();
            
            snapshot.forEach((childSnapshot) => {
             console.log('key',childSnapshot.key)
             console.log('val',childSnapshot.val())
             console.log('val',childSnapshot.val().header)
              var childKey = childSnapshot.key;
              var childData = childSnapshot.val();
              //console.log(childKey,childData)
              // ...

              var butwrap = $('<div/>',{class:"buttonwrap slide"});
              var but = $('<button/>',{class:"dynamicbutton"});
              but.attr('data-slide', JSON.stringify(childSnapshot.val()) );
              but.html( childSnapshot.val().label );
              butwrap.html(but);
              $('.innerbuttons').append( butwrap )
             // butwrap.insertAfter( activebut.parent() );






            });
            $('.innerbuttons .buttonwrap:first button').addClass('active');
            showcurrenttext();

          });
          


    }


    $('.nav-right').on('click',function(){
        nextslide();
    });

    $('.nav-left').on('click',function(){
        nextslide(false);
    });


    function nextslide(dir){
        if(dir==false){var el = $('.buttons button.active').parent().prev('.slide').find('button');}
        else{var el = $('.buttons button.active').parent().next('.slide').find('button');}
        if(el.length>0) {
            el.click()
            setactiveslide(el);
         }
    }

    function savebuttons(id){
        window.presdata = {};
        presdata.slides = [];
        $('.innerbuttons button').each(function(i,item){
            presdata.slides.push( JSON.parse($(item).attr('data-slide')) );
        })
       // console.log(presdata);
        var slides = firebase.database().ref('/expos/'+id+'/slides/').set(
            presdata.slides
        );
        console.log('saved');
        
    }


    $('.addslidebutton').on('click',function(){
        var s = panzoom.getScale().toFixed(5);
        var p = panzoom.getPan();
        p.x = (p.x/positionscale).toFixed(5);
        p.y = (p.y/positionscale).toFixed(5);        
        
        var butwrap = $('<div/>',{class:"buttonwrap slide"});
        var but = $('<button/>',{class:"dynamicbutton active"});
        but.attr('data-slide', '{"zoom":'+s+',"x":'+p.x+',"y":'+p.y+',"header":"","description":"","label":"Slide"}');
        but.html('Slide');
        butwrap.html(but);
        console.log(butwrap);
        var activebut = $('.buttons button.active');
        $('.buttons button').removeClass('active');
        butwrap.insertAfter( activebut.parent() );
       // $( ".innerbuttons" ).sortable({ items:"> .slide", axis: "x",containment: "parent",cursorAt: { left: 40,top:100 },revert: 50, tolerance: 'pointer',    cursor: 'move'});
       savebuttons(id);
       showcurrenttext();
    });

    $('.updateslidebutton').on('click',function(){


        
        var s = panzoom.getScale().toFixed(5);
        var p = panzoom.getPan();
        p.x = (p.x/positionscale).toFixed(5);
        p.y = (p.y/positionscale).toFixed(5);        
        
        var but = $('.dynamicbutton.active');
        but.attr('data-slide', '{"zoom":'+s+',"x":'+p.x+',"y":'+p.y+',"header":"'+ $('#inputslideheader').val() +'","description":"'+ $('#inputslidedescription').val() +'","label":"'+ $('#inputslidelabel').val() +'"}');
        but.html( $('#inputslidelabel').val()  );
        but.removeClass('latest');
        console.log(but);
        savebuttons(id);
       // $( ".innerbuttons" ).sortable({ items:"> .slide", axis: "x",containment: "parent",cursorAt: { left: 40,top:100 },revert: 50, tolerance: 'pointer',    cursor: 'move'});
    });


    

    $('.editslidebutton').on('click',function(){
        $('#inputslidelabel').val( $('button.active').html()  ) ;
        $('#inputslideheader').val( $('.text .header').html() ) ;
        $('#inputslidedescription').val( $('.text .description').html() )  ;
        MicroModal.show('modal-1'); 
        $('body').addClass('modal-active');
    })

    $('.modal-close:not(> main)').on('click',function(){
        $('body').removeClass('modal-active');
    })

    $('#modalsave').on('click', function(){
        
        $('.updateslidebutton').click();
        showcurrenttext();
        MicroModal.close('modal-1'); 
    });


    $('.removeslidebutton').on('click',function(){
        if(  $('.dynamicbutton').length >1 ) {
            var e =  $('.buttons button.active').parent();
            if(e.index()>0) {e.prev().find('button').addClass('active').click();}
            else {e.next().find('button').addClass('active').click();}
            e.remove();
            savebuttons(id);
        }
    })

    $('.togglefullscreen').on('click', function(){
        togglefullscreen( $('.wswrapper') );
    });

    $('.loginbutton').on('click',function(){

        MicroModal.show('modal-login'); 
        $('body').addClass('modal-active');
    })

    $('#startlogin').on('click',function(event){
        event.preventDefault();
        login();
        MicroModal.close('modal-login'); 
    });

    $('#startlogout').on('click',function(event){
        event.preventDefault();
        logout();
        MicroModal.close('modal-login'); 
    });

    function setactiveslide(el){
        
        if(el) {
            $('.buttons button').removeClass('active latest')
            $(el).addClass('active');
            showcurrenttext();
        }
        else{
            $('.buttons button.active').addClass('latest')
           // $('.text').removeClass('filled');
           $('#inputslidelabel').val( $('button.active').html()  ) ;
           $('#inputslideheader').val( $('.text .header').html() ) ;
           $('#inputslidedescription').val( $('.text .description').html() )  ;
        }
        
    }


    function showcurrenttext(){
        $('.text').removeClass('filled');


        var but = $('button.active');
        var data = JSON.parse( $(but).attr('data-slide') );


        setTimeout(function(){
            $('button.active').html(''); 
            $('.text .header').html(''); 
            $('.text .description').html('');

            $('button.active').html( data.label ) 
            
            $('#inputslidelabel').val( $('button.active').html()  ) ;
            $('#inputslideheader').val( $('.text .header').html() ) ;
            $('#inputslidedescription').val( $('.text .description').html() )  ;

            $('.text').addClass('filled');  
            $('.text .header').html( data.header) 
            $('.text .description').html( data.description)
        },500)
    }

    function togglefullscreen(el){    

        el.on('fullscreenchange', (event) => {
            // document.fullscreenElement will point to the element that
            // is in fullscreen mode if there is one. If not, the value
            // of the property is null.
            if (document.fullscreenElement) {
              console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
              $('body').addClass('fullscreenmode');  
              setTimeout(function(){adjustscale();},200);
            } else {
              console.log('Leaving full-screen mode.');
              $('body').removeClass('fullscreenmode');  
              setTimeout(function(){adjustscale();},200);
             // setTimeout(function(){adjustscale();},1500);
            }
          });
    
        // if already full screen; exit
        // else go fullscreen
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
        ) {
          
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          
          var element = el.get(0);
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
          }
        }
      }








});


function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}



